<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else>
      <div class="my-4">
        <h4 class="text-center">Список заявок на вакансии</h4>
      </div>

      <div class="my-3">
        <DataTable :value="commissionScore_form.candidateList" showGridlines stripedRows
                   :globalFilterFields="['lastname','firstname']"
                   v-model:filters="filters"
                   :paginator="true"
                   :rows="5"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[5,10,20,50]"
                   responsiveLayout="scroll"
                   currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск кандидата"/>
              </span>
            </div>
          </template>
          <template #empty>
            Кандидат не найден
          </template>
          <template #loading>
            Идет поиск кандидатов. Пожалуйста подождите.
          </template>

          <Column header="№" :style="{width: '80px'}">
            <template #body="{index}">
              {{index+1}}
            </template>
          </Column>

          <Column header="ФИО">
            <template #body="{data}">
              <a @click.prevent="goToUser(data.user)" class="text-decoration-none" href="#">
                {{data.lastname}} {{data.firstname}}
              </a>
              <div>
<!--                <Button icon="pi pi-user" class="p-button-rounded p-button-secondary m-1"/>-->
                <div>
                  Оценка за профиль кандидата ({{data.teacher_profile_score_votes}}): <strong>{{data.teacher_profile_score_sum}}</strong>
                  <br>
                </div>

<!--                <Button icon="pi pi-user" class="p-button-rounded p-button-help m-1"/>-->
                <div class="mt-3">
                  Анонимное голосование:
                  <br>
                  Количество за: <strong>{{data.anonymous_vote_sum_agree}}</strong>
                  <br>
                  Количество против: <strong>{{data.anonymous_vote_sum_disagree}}</strong>
                </div>

              </div>
            </template>
          </Column>

          <Column header="Дисциплины">
            <template #body="{data}">
              <div v-for="(application, index) in data.applications"
                   :key="index">

                <div class="fw-bold">
                  <a @click.prevent="openDisciplineInfoDialog(data.user, application.discipline_id)" class="text-decoration-none" href="#">
                    {{application.discipline_name}}
                  </a>
                </div>
                <div>
                  {{application.study_level_name}} - {{application.language}}

                  <div class="mt-2">
<!--                    <Button icon="pi pi-heart" class="p-button-rounded p-button-success m-1" style="width: 30px; height: 30px"/>-->
                    Оценка за дисциплину: <strong>{{application.discipline_teaching_score_sum}}</strong>
                    <br>
                    Количество за: <strong>{{application.kkk_anonymous_vote_sum_agree}}</strong>
                    <br>
                    Количество против: <strong>{{application.kkk_anonymous_vote_sum_disagree}}</strong>
<!--                    <Button icon="pi pi-file-pdf" class="p-button-rounded m-1" style="width: 30px; height: 30px"-->
<!--                            @click="goToSyllabus(data.user, application.discipline_id)"/>-->

<!--                    <Button icon="pi pi-comment" class="p-button-rounded p-button-help m-1" style="width: 30px; height: 30px"/>-->
                  </div>
                </div>
                <br>

              </div>
            </template>
          </Column>



        </DataTable>


      </div>


    </div>



    <Dialog header="Информация о дисциплине" :visible="disciplineInfoDisplay" :breakpoints="{'960px': '75vw'}" :style="{width: '50vw'}" :closable="false">

      <div class="col-md-12">

        <div v-for="(item, index) in disciplineInfo" :key="index">
          <p>
            <b>Название дисциплины:</b>{{item.code}} {{item.discipline_name}}
          </p>
          <p>
            <b>Язык:</b> {{item.language}}
          </p>
          <p>
            <b>Уровень обучения:</b> {{item.study_level_name}}
          </p>
          <p>
            <b>Описание:</b> {{item.description}}
          </p>
          <p>
            <b>Умения:</b> {{item.abilities}}
          </p>
          <p>
            <b>Навыки:</b> {{item.skills}}
          </p>
          <p>
            <b>Знания:</b> {{item.knowledge}}
          </p>
        </div>
      </div>

      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeDisciplineInfoDialog" class="p-button-text"/>
      </template>
    </Dialog>

  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex'
import {FilterMatchMode, FilterOperator} from 'primevue/api';

export default {
  name: 'HRScore',
  data() {
    return {
      loading: true,
      interval: null,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},

        'lastname': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'firstname': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
      },
      // checkedApplications: [],
      // rejectDisplay: false,
      // reject_user: null,

      disciplineInfoDisplay: false,
      info_discipline_id: null,
      info_user_id: null,

      teacherProfileScoreDisplay: false,
      teacherProfileScore: {},
      teacher_profile_score_user_id: null,

      disciplineTeachingScoreDisplay: false,
      disciplineTeachingScore: {},
      discipline_teaching_score_kkk_interview_time_id: null,
      discipline_teaching_score_user_id: null,

      anonymousVoteDisplay: false,
      anonymous_vote_user_id: null,
      selected_profile_types: {},

      disciplineAnonymousVoteDisplay: false,
      discipline_anonymous_vote_kkk_interview_time_id: null,
      discipline_anonymous_vote_user_id: null
    }
  },
  computed: {
    ...mapState('commissionScore', ['commissionScore_form']),
    disciplineInfo() {
      return this.commissionScore_form.candidateList.find(i=>i.user == this.info_user_id).applications.filter(i=>i.discipline_id == this.info_discipline_id)
    },
    teacherProfileScoreInfo() {
      return this.commissionScore_form.candidateList.find(i=>i.user === this.teacher_profile_score_user_id)?.teacher_profile_score
    },
    disciplineTeachingScoreInfo() {
      return this.commissionScore_form.candidateList
          .find(i=>i.user === this.discipline_teaching_score_user_id)?.applications
          .find(i=>i.kkk_interview_time_id === this.discipline_teaching_score_kkk_interview_time_id).discipline_teaching_score
    },
    filteredTeacherProfileScoreCriterion() {
      if (this.selected_profile_types.simple == 1) {
        return this.commissionScore_form.teacherProfileScoreCriterion.filter(i=>i.id != 6)
      }
      else if (this.selected_profile_types.lang == 1) {
        return this.commissionScore_form.teacherProfileScoreCriterion.filter(i=>i.id == 1 || i.id == 5)
      }
      else if (this.selected_profile_types.practic == 1) {
        return this.commissionScore_form.teacherProfileScoreCriterion.filter(i=>i.id == 1 || i.id == 6)
      }
      else {
        return this.commissionScore_form.teacherProfileScoreCriterion
      }
    },
    anonymousVoteInfo() {
      return this.commissionScore_form.candidateList.find(i=>i.user === this.anonymous_vote_user_id).anonymous_vote
    },
    disciplineAnonymousVoteInfo() {



      return this.commissionScore_form.candidateList.find(i=>i.user === this.discipline_anonymous_vote_user_id).applications
          .find(i=>i.kkk_interview_time_id === this.discipline_anonymous_vote_kkk_interview_time_id).kkk_anonymous_vote
    }
    // disciplineAnonymousVoteInfo() {
    //   return this.commissionScore_form.candidateList.find(i=>i.user === this.anonymous_vote_user_id).anonymous_vote
    // }
  },
  methods: {
    ...mapActions('commissionScore', ['GET_CANDIDATE_LIST', 'GET_DISCIPLINE_TEACHING_SCORE_CRITERION',
      'GET_TEACHER_PROFILE_SCORE_CRITERION', 'POST_TEACHER_PROFILE_SCORE', 'POST_DISCIPLINE_TEACHING_SCORE', 'POST_ANONYMOUS_VOTE', 'POST_DISCIPLINE_ANONYMOUS_VOTE']),
    ...mapMutations('userinfo', ['SET_USER_ID']),


    openAnonymousVoteDialog(user_id) {
      this.anonymousVoteDisplay = true
      this.anonymous_vote_user_id = user_id
    },
    closeAnonymousVoteDialog() {
      this.anonymousVoteDisplay = false
    },
    async submitDisciplineAnonymousVote(vote) {
      this.disciplineAnonymousVoteDisplay = false
      let form = {}
      form['vote'] = vote
      form['kkk_interview_time_id'] = this.discipline_anonymous_vote_kkk_interview_time_id



      console.log(form, 'submit discipline anonymous vote')

      let postData = await this.POST_DISCIPLINE_ANONYMOUS_VOTE(form)
      if (postData) {
        await this.GET_CANDIDATE_LIST()
        this.$message({text: 'Выбор сохранен'})
      }

      this.discipline_anonymous_vote_kkk_interview_time_id = null


    },
    openDisciplineAnonymousVoteDialog(kkk_interview_time_id, user_id) {
      this.disciplineAnonymousVoteDisplay = true
      this.discipline_anonymous_vote_kkk_interview_time_id = kkk_interview_time_id
      this.discipline_anonymous_vote_user_id = user_id
    },
    closeDisciplineAnonymousVoteDialog() {
      this.disciplineAnonymousVoteDisplay = false
    },
    openDisciplineInfoDialog(user_id, discipline_id) {
      this.disciplineInfoDisplay = true
      console.log(discipline_id, 'discipline info discipline_id ')
      console.log(user_id, 'discipline info user_id ')
      this.info_discipline_id = discipline_id
      this.info_user_id = user_id
    },
    closeDisciplineInfoDialog() {
      this.disciplineInfoDisplay = false
    },
    goToUser(userId) {
      this.SET_USER_ID(userId)
      this.$router.push('/user')
    },
    goToSyllabus(user_id, discipline_id) {

      window.open(`https://back.uib.kz/syllabus_data/syllabus-print/get-syllabus?user_id=${user_id}&education_discipline_id=${discipline_id}`)
      //this.$router.push(`https://back.uib.kz/syllabus_data/syllabus-print/get-syllabus?user_id=${user_id}&education_discipline_id=${discipline_id}`)
    },
    openDisciplineTeachingScoreDialog(kkk_interview_time_id, user_id) {

      console.log(kkk_interview_time_id, 'openDisciplineTeachingScoreDialog kkk_interview_time_id')
      console.log(user_id, 'openDisciplineTeachingScoreDialog user_id')
      this.disciplineTeachingScoreDisplay = true
      this.discipline_teaching_score_kkk_interview_time_id = kkk_interview_time_id
      this.discipline_teaching_score_user_id = user_id
    },
    closeDisciplineTeachingScoreDialog() {
      this.disciplineTeachingScoreDisplay = false
    },
    changeDisciplineTeachingScore(property, e, val = 'value') {
      let value = e.target[val]
      this.disciplineTeachingScore[property] = value
      console.log(this.disciplineTeachingScore, 'disciplineTeachingScore')
    },
    openTeacherProfileScoreDialog(user_id, profile_types){
      this.teacherProfileScoreDisplay = true
      this.teacher_profile_score_user_id = user_id
      this.selected_profile_types = profile_types
      console.log(this.selected_profile_types, 'selected_profile_types')
    },
    closeTeacherProfileScoreDialog(){
      this.teacherProfileScoreDisplay = false
    },
    changeTeacherProfileScore(property, e, val = 'value') {
      let value = e.target[val]
      this.teacherProfileScore[property] = value
      console.log(this.teacherProfileScore, 'teacherProfileScore')
    },



  },


  async mounted() {
    await this.GET_CANDIDATE_LIST()
    await this.GET_DISCIPLINE_TEACHING_SCORE_CRITERION()
    await this.GET_TEACHER_PROFILE_SCORE_CRITERION()
    this.loading = false;
    this.interval = setInterval(() => {
      this.GET_CANDIDATE_LIST()
    }, 5000)
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
}

</script>